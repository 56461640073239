<template>
  <div id="page-live-periods">
    <Confirm
      :title="$t('PERIOD.CONFIRM')"
      :message="$t('PERIOD.CONFIRM_DELETE')"
      ref="deletePeriodConfirm"
      type="success"
      @confirm="onDeletePeriodConfirm"
    />
    <PeriodTable
      :periods="periods"
      :months="months"
      type="live"
      @createPeriodClicked="createPeriod"
      @selectPeriodClicked="selectPeriod"
      @deletePeriodClicked="deletePeriodClicked"
      @toastr="toastr"
    />
    <div class="scroll-to-container"></div>
    <b-card
      title=""
      class="mb-2 edit-live-period-container"
      ref="edit-live-period"
      id="edit-live-period"
      hide-footer
      v-if="creating || editing"
    >
      <div>
        <b-form class="mt-8" @submit="submitPeriod">
          <b-form-group id="input-group-period_id" label="ID" label-for="input-period_id">
            <b-form-input
              id="input-period_id"
              v-model="form.id"
              type="text"
              required
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-1" :label="$t('PERIOD.NAME_OF_PERIOD')" label-for="input-1">
            <b-form-input id="input-1" v-model="form.name" type="text"></b-form-input>
          </b-form-group>

          <b-row>
            <b-col md="6" sm="12">
              <b-form-group id="input-group-2" :label="$t('PERIOD.FROM')" label-for="input-2">
                <memlist-date-picker v-model="form.from"></memlist-date-picker>
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group id="input-group-3" :label="$t('PERIOD.TO')" label-for="input-3">
                <memlist-date-picker v-model="form.to"></memlist-date-picker>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6" sm="12">
              <b-form-group id="input-group-2" :label="$t('PERIOD.MDT_FROM')" label-for="input-2">
                <memlist-date-picker v-model="form.mdt_from"></memlist-date-picker>
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group id="input-group-3" :label="$t('PERIOD.MDT_TO')" label-for="input-3">
                <memlist-date-picker v-model="form.mdt_to"></memlist-date-picker>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col v-if="!form.is_infinite" md="6" sm="12">
              <b-form-group id="year-group" :label="$t('PERIOD.YEAR')" label-for="year">
                <b-form-select
                  id="year"
                  v-model="form.year"
                  :options="year_options"
                />

              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group id="next_period-group" :label="$t('PERIOD.NEXT_PERIOD')" label-for="next_period">

                <b-form-select
                  id="next_period"
                  v-model="form.next_period_id"
                  :options="period_options"
                />

              </b-form-group>
            </b-col>
          </b-row>

          <b-button type="submit" variant="primary">{{$t('COMMON.SAVE')}}</b-button>
        </b-form>
      </div>
    </b-card>
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import PeriodTable from '@/view/pages/ml/periods/PeriodTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import dayjs from 'dayjs';

export default {
  name: 'periods',
  components: {
    PeriodTable,
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  props: ['months'],
  mounted() {

    this.year_options = [];

    const current_year = parseInt(dayjs().format('YYYY'));

    for (let year = 1900; year < current_year + 25; ++year) {
      this.year_options.push({ value: year, text: year+'' });
    }

    this.loadPeriods();
  },
  data() {
    return {
      form: {
        id: '',
        from: '',
        to: '',
        name: '',
        year: 1900,
        mdt_from: '',
        mdt_to: '',
      },
      period_options: [],
      year_options: [],
      loaded: false,
      creating: false,
      editing: false,
      periods: [],
      toDelete: null
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadPeriods();
      }
    }
  },
  methods: {
    async loadData() {
      if (!this.currentCompanyId) {
        return;
      }
      if (!this.titles) {
        return;
      }

      this.loadPeriods();
    },
    submitPeriod(e) {
      e.preventDefault();

      this.updatePeriod();
    },
    updatePeriod() {
      if (this.creating) {
        axios
          .post('/period', this.form)
          .then(res => {
            if (res.status === 201) {
              this.creating = false;
              this.form = res.data;
              this.toastr('success', this.$t('COMMON.OK'), this.$t('PERIOD.CREATED'));
              this.loadPeriods();
            } else if (res.status === 400) {
              this.toastr('warning', this.$t('COMMON.ERROR'), this.$t('PERIOD.FILL_IN_ALL_FIELDS'));
            } else if (res.status === 409) {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERIOD.UNABLE_OVERLAP'));
            } else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERIOD.UNABLE_CREATE'));
            }
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERIOD.UNABLE_CREATE'));
          });
      } else {
        axios
          .put(`/period/${this.form.id}`, this.form)
          .then(res => {
            if (res.status === 204) {
              this.creating = false;
              this.toastr('success', this.$t('COMMON.OK'), this.$t('PERIOD.UPDATED'));
              this.loadPeriods();
            }
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERIOD.UNABLE_UPDATE'));
          });
      }
    },
    createPeriod() {
      this.creating = true;
      this.editing = true;
      this.form = {};

      this.scrollToEditPeriod();

      this.form.company_id = this.currentCompanyId;
      this.form.period_id = this.currentPeriodId;
    },
    deletePeriodClicked(period) {
      this.toDelete = period;
      this.$refs['deletePeriodConfirm'].show();
    },
    onDeletePeriodConfirm() {
      this.deletePeriod(this.toDelete);
      this.toDelete = null;
    },
    deletePeriod(period_id) {
      axios
        .delete(`/period/${period_id}?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 204) {
            // this.periods = this.periods.filter(item => item.id !== period_id);

            this.creating = false;
            this.editing = false;

            this.toastr('success', this.$t('COMMON.OK'), this.$t('PERIOD.DELETED'));

            this.loadPeriods();
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERIOD.UNABLE_DELETE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERIOD.UNABLE_DELETE'));
        });
    },
    selectPeriod(period_id) {
      for (var i = 0; i < this.periods.length; ++i) {
        if (this.periods[i].id === period_id) {
          this.form = this.periods[i];
        }
      }
      this.form.company_id = this.currentCompanyId;
      this.creating = false;
      this.editing = true;

      this.scrollToEditPeriod();
    },

    async loadPeriods() {
      this.periods = [];
      axios
        .get(`/period`)
        .then(res => {
          if (res.status === 200) {
            this.periods = res.data;
            this.period_options = [];

            this.periods = this.periods.sort((a,b) => {
              if (a.from < b.from) {
                return -1;
              }
              return 1;
            });

            for (const period of this.periods) {
              this.period_options.push({ value: period.id, text: period.name });
            }

            this.loaded = true;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERIOD.UNABLE_LIST'));
        });
    },
    scrollToEditPeriod() {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName('edit-live-period-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    }
  }
};
</script>
